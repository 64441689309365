// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-become-a-model-jsx": () => import("./../../../src/pages/become-a-model.jsx" /* webpackChunkName: "component---src-pages-become-a-model-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-models-jsx": () => import("./../../../src/pages/models.jsx" /* webpackChunkName: "component---src-pages-models-jsx" */),
  "component---src-pages-models-main-jsx": () => import("./../../../src/pages/models/main.jsx" /* webpackChunkName: "component---src-pages-models-main-jsx" */),
  "component---src-pages-models-new-jsx": () => import("./../../../src/pages/models/new.jsx" /* webpackChunkName: "component---src-pages-models-new-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-production-jsx": () => import("./../../../src/pages/production.jsx" /* webpackChunkName: "component---src-pages-production-jsx" */),
  "component---src-templates-model-jsx": () => import("./../../../src/templates/model.jsx" /* webpackChunkName: "component---src-templates-model-jsx" */)
}

